import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { map } from './core/MapView';

import './control-icons.css';

const statusClass = (status) => `maplibregl-ctrl-icon maplibre-ctrl-replay maplibre-ctrl-replay-${status}`;

class ReplayControl {
  constructor(position, eventHandler) {
    this.position = position;
    this.eventHandler = eventHandler;
  }

  onAdd() {
    // eslint-disable-next-line no-undef
    this.button = document.createElement('button');
    this.button.className = statusClass('off');
    this.button.type = 'button';
    this.button.onclick = () => this.eventHandler(this);

    // eslint-disable-next-line no-undef
    this.container = document.createElement('div');
    this.container.className = 'maplibregl-ctrl-group maplibregl-ctrl';
    this.container.appendChild(this.button);

    return this.container;
  }

  onRemove() {
    this.container.parentNode.removeChild(this.container);
  }

  setEnabled(enabled) {
    this.button.className = statusClass(enabled ? 'on' : 'off');
  }
}

const MapToReplay = ({ enabled, selectedPosition }) => {
  const navigate = useNavigate();
  const clickHandler = () => navigate('/replay');
  const control = new ReplayControl(selectedPosition, clickHandler);

  useEffect(() => {
    map.addControl(control);
    return () => map.removeControl(control);
  }, [selectedPosition]);

  useEffect(() => {
    control.setEnabled(enabled);
  }, [enabled]);

  return null;
};

export default MapToReplay;
