/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable quotes */
import Tune from "@mui/icons-material/Tune";
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  InputLabel,
  ListItem,
  Menu,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  List,
  Box,
  Slider
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import SpeedIcon from '@mui/icons-material/Speed';
import NotificationsIcon from '@mui/icons-material/Notifications';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import moment from "moment";
import EventsDrawer from '../main/EventsDrawer';
import usePositionAttributes from "../common/attributes/usePositionAttributes";
import { useTranslation } from "../common/components/LocalizationProvider";
import PageLayout from "../common/components/PageLayout";
import PositionValue from "../common/components/PositionValue";
import { useAttributePreference } from "../common/util/preferences";
import { devicesActions } from "../store";
import ControlMenu from "./ControlMenu";
import { useCatch } from '../reactHelper';
import { ReactComponent as EngineIcon } from '../resources/images/data/engine.svg';
import { formatStatus } from "../common/util/formatter";
import Timer from "../common/components/Timer";

const useStyles = makeStyles((theme) => ({
  card: {
    pointerEvents: "auto",
    width: theme.dimensions.popupMaxWidth,
  },
  media: {
    height: theme.dimensions.popupImageHeight,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
  },
  mediaButton: {
    color: theme.palette.colors.white,
    mixBlendMode: "difference",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1, 1, 0, 2),
  },
  content: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  negative: {
    color: theme.palette.colors.negative,
  },
  positive: {
    color: theme.palette.colors.positive,
  },
  medium: {
    color: theme.palette.colors.medium,
  },
  neutral: {
    color: theme.palette.colors.neutral,
  },
  icon: {
    width: "25px",
    height: "25px",
    filter: "brightness(0) invert(1)",
  },
  table: {
    "& .MuiTableCell-sizeSmall": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  cell: {
    borderBottom: "none",
  },
  actions: {
    justifyContent: "space-between",
  },
  root: ({ desktopPadding }) => ({
    pointerEvents: "none",
    position: "fixed",
    zIndex: 5,
    left: "50%",
    [theme.breakpoints.up("md")]: {
      left: `calc(50% + ${desktopPadding} / 2)`,
      bottom: theme.spacing(3),
    },
    [theme.breakpoints.down("md")]: {
      left: "50%",
      bottom: `calc(${theme.spacing(3)} + ${theme.dimensions.bottomBarHeight
      }px)`,
    },
    transform: "translateX(-50%)",
  }),
}));
const useCustomStyles = makeStyles((theme) => ({
  buttonBig: {
    width: 160,
    height: 160,
    borderRadius: "50%",
    background: "#fff",
    // color: "#000",
    color: theme.palette.colors.negative,
  },
}));

const DeviceControlPage = () => {
  const classes = useStyles();
  const devices = useSelector((state) => state.devices.items);
  const deviceId = useSelector((state) => state.devices.selectedId);
  const positions = useSelector((state) => state.session.positions);
  const navigate = useNavigate();
  const device = devices[deviceId];
  const deviceImage = device?.attributes?.deviceImage;
  const selectedPosition = positions?.[deviceId];
  const positionItems = useAttributePreference(
    "positionItems",
    "speed,address,deviceTime"
  );
  const t = useTranslation();
  const dispatch = useDispatch();
  const positionAttributes = usePositionAttributes(t);
  const styles = useCustomStyles();

  const eventsAvailable = useSelector((state) => !!state.events.items.length);

  const [openLock, setLockOpen] = useState(false);
  const [openUnlock, setUnlockOpen] = useState(false);
  const [speedLimitOpen, setSpeedLimitOpen] = useState(false);
  const [openStart, seStartOpen] = useState(false);
  const [openSuccess, setSuccessOpen] = useState(false);

  const [eventsOpen, setEventsOpen] = useState(false);

  const [value, setValue] = React.useState();

  useEffect(() => {
    if (!value && device) {
      setValue(device.attributes.speedLimit ? device.attributes.speedLimit * 1.852 : 0);
    }
  }, [device]);

  const handleClickStartOpen = () => {
    seStartOpen(true);
  };

  const handleStartClose = () => {
    seStartOpen(false);
  };

  const handleLockOpen = () => {
    setLockOpen(true);
  };

  const handleLockClose = () => {
    setLockOpen(false);
  };

  const handleUnlockOpen = () => {
    setUnlockOpen(true);
  };

  const handleUnlockClose = () => {
    setUnlockOpen(false);
  };

  const handleSuccessOpen = () => {
    setSuccessOpen(true);
  };

  const handleSuccessClose = () => {
    setSuccessOpen(false);
  };

  const saveCommandName = (response, command) => {
    if (response.status === 200) {
    // eslint-disable-next-line no-undef
      window.smartq.command = command;
    }
  };

  const handleLock = useCatch(async () => {
    const raw = JSON.stringify({
      id: 0,
      deviceId,
      description: "LOCK",
      type: "outputControl",
      textChannel: false,
      attributes: { index: 6, data: "1" }
    });

    const response = await fetch('/api/commands/send', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: raw,
    });

    saveCommandName(response, 'LOCK');

    handleLockClose();
    handleSuccessOpen();
  });

  const handleStart = useCatch(async () => {
    const raw = JSON.stringify({
      id: 0,
      deviceId,
      description: "START/STOP",
      type: "outputControl",
      textChannel: false,
      attributes: { index: 3, data: "1" }
    });

    const response = await fetch('/api/commands/send', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: raw,
    });

    saveCommandName(response, 'START/STOP');

    handleStartClose();
    handleSuccessOpen();
  });

  const handleUnlock = useCatch(async () => {
    const raw = JSON.stringify({
      id: 0,
      deviceId,
      description: "UNLOCK",
      type: "outputControl",
      textChannel: false,
      attributes: { index: 5, data: "1" }
    });

    const response = await fetch('/api/commands/send', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: raw,
    });

    saveCommandName(response, 'UNLOCK');

    handleUnlockClose();
    handleSuccessOpen();
  });
  

  const handleChange = (event, newValue) => {
    if (typeof newValue === 'number') {
      setValue(newValue);
    }
  };

  const handleSave = useCatch(async () => {
    const { attributes } = devices[deviceId];
    const device = { ...devices[deviceId], attributes: { ...attributes, speedLimit: (value / 1.852) } };

    await fetch(`/api/devices/${device.id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(device),
    });

    setSpeedLimitOpen(false);
    handleSuccessOpen();
  });

  const getDeviceStatusStyle = () => {
    if (device && device.status === 'online') {
      return classes.positive;
    } if (device && device.status === 'offline') {
      return classes.negative;
    }
    return classes.medium;
  };

  // console.log(device);
  // console.log(selectedPosition);

  return (
    <PageLayout breadcrumbs={["sharedDevice"]} menu={<ControlMenu />}>
      <List>
        <Card
          elevation={0}
          style={{
            padding: 10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <FormControl style={{ margin: "10px 0", width: 200, zIndex: 99 }}>
            <InputLabel>{t("reportDevice")}</InputLabel>
            <Select
              label={t("deviceTitle")}
              value={deviceId || ""}
              onChange={(e) => dispatch(devicesActions.selectId(e.target.value))}
            >
              {Object.values(devices)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((device) => (
                  <MenuItem key={device.id} value={device.id}>
                    {device.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <div style={{
            position: "absolute",
            height: "200px",
            width: "300px",
            // outline: "red solid 1px",
          }}
          >
            <div style={{
              paddingTop: "8px",
              marginTop: "10px",
              float: "left",
              width: "40px",
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              // outline: "1px solid blue"
            }}
            >
              {device && <Typography sx={{ marginRight: '20px' }} className={getDeviceStatusStyle()}>{formatStatus(device.status, t)}</Typography>}
            </div>
            <div style={{
              marginTop: "10px",
              float: "right",
              width: "40px",
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              // outline: "1px solid blue"
            }}
            >
              <div>
                <button
                  type="button"
                  onClick={() => setEventsOpen(true)}
                  style={{
                    marginLeft: -5,
                    border: 0,
                    width: 32,
                    height: 32,
					display: 'flex',
                    borderRadius: "5px",
                    background: "#fff",
                    color: "#000",
                  }}
                >
                  <div>
                    <NotificationsIcon className={eventsAvailable ? classes.negative : classes.neutral} />
                  </div>
                </button>
              </div>
              <div>
                <button
                  type="button"
                  onClick={() => setSpeedLimitOpen(true)}
                  style={{
                    marginLeft: -5,
                    border: 0,
                    width: 32,
                    height: 32,
					display: 'flex',
                    marginTop: "10px",
                    borderRadius: "5px",
                    background: "#fff",
                    color: "#000",
                  }}
                >
                  <div>
                    <SpeedIcon className={classes.neutral} />
                  </div>
                </button>
              </div>

              <div>
                <button
                  type="button"
                  onClick={() => navigate('/settings/startstops')}
                  style={{
                    marginLeft: -5,
                    border: 0,
                    width: 32,
                    height: 32,
					display: 'flex',
                    marginTop: "10px",
                    borderRadius: "5px",
                    background: "#fff",
                    color: "#000",
                  }}
                >
                  <div>
                    <EventRepeatIcon className={classes.neutral} />
                  </div>
                </button>
              </div>
              {selectedPosition &&
              <div>
                <button
                  type="button"
                  onClick={() => setEventsOpen(true)}
                  style={{
                    marginLeft: -20,
                    border: 0,
                    width: 32,
                    height: 32,
					display: 'flex',
                    borderRadius: "5px",
                    background: "#fff",
                    color: "#000",
                  }}
                >
                  <div style={{ marginTop: 10 }}>
                    <EngineIcon width={32} height={32} className={selectedPosition && selectedPosition.attributes.in5 ? classes.positive : classes.neutral} />
                    <Typography className={selectedPosition.attributes.iginition ? classes.positive : classes.neutral} sx={{ marginLeft: '-10px' }} variant="overline">
                      { selectedPosition.attributes.in5 ? <Timer startTime={moment(device.attributes.ignitionTime)} /> : '00:00:00'}
                    </Typography>
                  </div>
                </button>
              </div>}
            </div>
          </div>

          {deviceId && (
            <>
              {deviceImage && (
                <img
                  src={`/api/media/${device.uniqueId}/${deviceImage}`}
                  alt="new"
                  width={190}
                  height={90}
                />
              )}

              {selectedPosition && (
                <CardContent>
                  {/** className={classes.content} */}

                  <Table size="small" style={{ width: "unset" }}>
                    {/** classes={{ root: classes.table }} */}
                    <TableBody>
                      {positionItems
                        .split(",")
                        .filter(
                          (key) =>
                            selectedPosition.hasOwnProperty(key) ||
                            selectedPosition.attributes.hasOwnProperty(key)
                        )
                        .map((key) => (
                          <StatusRow
                            key={key}
                            name={
                              positionAttributes.hasOwnProperty(key)
                                ? positionAttributes[key].name
                                : key
                            }
                            content={
                              <PositionValue
                                position={selectedPosition}
                                property={
                                  selectedPosition.hasOwnProperty(key)
                                    ? key
                                    : null
                                }
                                attribute={
                                  selectedPosition.hasOwnProperty(key)
                                    ? null
                                    : key
                                }
                              />
                            }
                          />
                        ))}
                    </TableBody>
                  </Table>
                </CardContent>
              )}

              <div>
                <Button
                  // classes={styles.buttonBig}
                  // onClick={handleStart}
                  onClick={handleClickStartOpen}
                  style={{
                    width: 120,
                    height: 120,
                    borderRadius: "50%",
                    background: "#fff",
                    color: "#000",
                  }}
                  variant="contained"
                >
                  <div>
                    <PowerSettingsNewIcon style={{ fontSize: 60 }} />
                    <div style={{ marginTop: -15, fontSize: 12 }}>ENGINE START/STOP</div>
                  </div>
                </Button>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  padding: 20,
                  textAlign: "center",
                }}
              >
                <div>
                  <Button
                    onClick={handleLockOpen}
                    style={{
                      width: 60,
                      height: 60,
                      borderRadius: "50%",
                      background: "#fff",
                      color: "#000",
                    }}
                    variant="contained"
                  >
                    <div>
                      <LockIcon style={{ fontSize: 40 }} />
                    </div>
                  </Button>
                  <div style={{ marginTop: 10 }}>Lock</div>
                </div>
                <div>
                  <Button
                    style={{
                      width: 60,
                      height: 60,
                      borderRadius: "50%",
                      background: "#fff",
                      color: "#000",
                      margin: "0 30px",
                    }}
                    variant="contained"
                  >
                    <InsertLinkIcon
                      onClick={() => navigate(`/settings/device/${deviceId}/command`)}
                      style={{ fontSize: 40, transform: "rotate(140deg)" }}
                    />
                  </Button>
                  <div style={{ marginTop: 10, color: 'red' }}>{ device && device.attributes.deviceBlocked ? 'Blocked' : ''}</div>
                </div>
                <div>
                  <Button
                    onClick={handleUnlockOpen}
                    style={{
                      width: 60,
                      height: 60,
                      borderRadius: "50%",
                      background: "#fff",
                      color: "#000",
                    }}
                    variant="contained"
                  >
                    <div>
                      <LockOpenIcon style={{ fontSize: 40 }} />
                    </div>
                  </Button>
                  <div style={{ marginTop: 10 }}>Unlock</div>
                </div>
              </div>
            </>
          )}
          {!deviceId && (
            <div style={{ textAlign: "center" }}>Please select a device.</div>
          )}
          <div>
            <Dialog
              open={openStart}
              onClose={handleStartClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title" style={{ fontSize: 18 }}>
                SEND COMMAND
              </DialogTitle>
              <DialogActions>
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  onClick={handleStartClose}
                >
                  Cancel

                </Button>
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  onClick={handleStart}
                  autoFocus
                >
                  ACCEPT
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={openLock}
              onClose={handleLockClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title" style={{ fontSize: 18 }}>
                SEND COMMAND
              </DialogTitle>
              <DialogActions>
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  onClick={handleLockClose}
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  onClick={handleLock}
                  autoFocus
                >
                  ACCEPT
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={openUnlock}
              onClose={handleUnlockClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title" style={{ fontSize: 18 }}>
                SEND COMMAND
              </DialogTitle>
              <DialogActions>
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  onClick={handleUnlockClose}
                >
                  Cancel

                </Button>
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  onClick={handleUnlock}
                  autoFocus
                >
                  ACCEPT
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={speedLimitOpen}
              onClose={() => setSpeedLimitOpen(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title" style={{ fontSize: 18 }}>
                Speed Limit:
                {' '}
                {value}
                {' '}
                Km/h
              </DialogTitle>
              <Box width={300} p={2}>
                <Slider
                  step={20}
                  marks
                  min={20}
                  max={220}
                  aria-label="Speed Limit"
                  value={value}
                  onChange={handleChange}
                  valueLabelDisplay="auto"
                />
              </Box>
              <DialogActions>
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  onClick={() => setSpeedLimitOpen(false)}
                >
                  Cancel
                </Button>

                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  onClick={handleSave}
                  autoFocus
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>

          </div>
        </Card>
      </List>
      <EventsDrawer open={eventsOpen} onClose={() => setEventsOpen(false)} />
    </PageLayout>
  );
};

const StatusRow = ({ name, content }) => {
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell className={classes.cell}>
        <Typography variant="body2">{name}</Typography>
      </TableCell>
      <TableCell className={classes.cell}>
        <Typography variant="body2" color="textSecondary">
          {content}
        </Typography>
      </TableCell>
    </TableRow>
  );
};
export default DeviceControlPage;
