import { useEffect } from 'react';
import { map } from './core/MapView';

import './control-icons.css';

const statusClass = (status) => `maplibregl-ctrl-icon maplibre-ctrl-location-searching maplibre-ctrl-location-searching-${status}`;

class GoogleMapsControl {
  constructor(position) {
    this.position = position;
  }

  onAdd() {
    // eslint-disable-next-line no-undef
    this.button = document.createElement('button');
    this.button.className = statusClass('off');
    this.button.type = 'button';
    // eslint-disable-next-line no-undef
    this.link = document.createElement('a');
    if (this.position) {
      this.link.target = '_blank';
      this.link.href = `https://www.google.com/maps/search/?api=1&query=${this.position.latitude}%2C${this.position.longitude}`;
    }
    this.link.appendChild(this.button);

    // eslint-disable-next-line no-undef
    this.container = document.createElement('div');
    this.container.className = 'maplibregl-ctrl-group maplibregl-ctrl';
    this.container.appendChild(this.link);

    return this.container;
  }

  onRemove() {
    this.container.parentNode.removeChild(this.container);
  }

  setEnabled(enabled) {
    this.button.className = statusClass(enabled ? 'on' : 'off');
  }
}

const MapToGoogleMaps = ({ enabled, selectedPosition }) => {
  const control = new GoogleMapsControl(selectedPosition);

  useEffect(() => {
    map.addControl(control);
    return () => map.removeControl(control);
  }, [selectedPosition]);

  useEffect(() => {
    control.setEnabled(enabled);
  }, [enabled]);

  return null;
};

export default MapToGoogleMaps;
