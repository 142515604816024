import React, { useEffect, useState } from 'react';
import moment from 'moment';

const Timer = ({ startTime }) => {
  const [currentTime, setCurrentTime] = useState(moment());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment());
    }, 1000); // Update every second

    return () => clearInterval(timer);
  }, []);

  const duration = moment.duration(currentTime.diff(startTime));
  const formattedTime = `${String(duration.hours()).padStart(2, '0')}:${String(duration.minutes()).padStart(2, '0')}:${String(duration.seconds()).padStart(2, '0')}`;

  return <span>{formattedTime}</span>;
};

export default Timer;
