import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import TuneIcon from '@mui/icons-material/Tune';

const MenuItem = ({ title, link, icon, selected }) => (
  <ListItemButton key={link} component={Link} to={link} selected={selected}>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText primary={title} />
  </ListItemButton>
);
const ControlMenu = () => {
  const location = useLocation();
  return (
    <List>
      <MenuItem
        title="Devices"
        link="/controls/device"
        icon={<TuneIcon />}
        selected={location.pathname === '/controls/device'}
      />
    </List>
  );
};

export default ControlMenu;
